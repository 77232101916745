import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  icon?: string
  image?: ImageProps
  label?: string
}

export const IconBox = memo(function IconBox({
  description,
  icon,
  image,
  label,
}: Props) {
  if (!label) {
    return null
  }

  return (
    <Container row stretch>
      <Wrapper>
        {icon ? (
          <FadeInUp>
            <Icon src={icon} alt={label} width="60" height="60" />
          </FadeInUp>
        ) : null}

        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Wrapper>

      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  min-height: 31.25rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 1199px) {
    min-height: auto;
    flex-wrap: wrap;
  }
`

const Wrapper = styled.div`
  flex: 1;
  padding: 3.75rem;

  @media (max-width: 1199px) {
    width: 100%;
    order: 2;
    padding: 3rem 1.875rem;
  }
`

const Icon = styled.img`
  width: auto;
  max-width: 3.75rem;
  height: 3.75rem;
  margin-bottom: 1rem;

  @media (max-width: 1199px) {
    max-width: 2.5rem;
    height: 2.5rem;
  }
`

const Label = styled.div`
  max-width: 6.25rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.5rem;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }
`

const Background = styled.div`
  width: 67%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    aspect-ratio: 1.3;
  }
`
