import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { IconBox, Props as IconBoxProps } from 'app/components/Common/IconBox'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  cta?: ButtonProps
  icons?: IconBoxProps[]
  images?: ImageProps[]
  label?: string
  title?: string
}

export const VisualServices = memo(function VisualServices({
  cta,
  icons,
  images,
  label,
  title,
}: Props) {
  return (
    <Container row stretch tag="section" wrap>
      <LeftSide dial={4}>
        {images && images[0] ? (
          <ParallaxProvider>
            <LazyLoadComponent>
              <Parallax className="main-image" translateY={['-30%', '30%']}>
                <Image {...images[0]} />
              </Parallax>
            </LazyLoadComponent>
          </ParallaxProvider>
        ) : null}

        <Wrapper>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {cta ? (
            <FadeInUp>
              <CTA {...cta} variant="invert" />
            </FadeInUp>
          ) : null}
        </Wrapper>
      </LeftSide>

      <RightSide>
        <Thumb>
          {images && images[1] ? (
            <LazyLoadComponent>
              <Image {...images[1]} />
            </LazyLoadComponent>
          ) : null}
        </Thumb>

        {icons ? (
          <Icons row stretch>
            {icons.map((item, index) => (
              <IconBox key={index} {...item} />
            ))}
          </Icons>
        ) : null}
      </RightSide>
    </Container>
  )
})

const ImageStyle = css`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    img {
      display: none;
    }
  }
`

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const LeftSide = styled(FlexBox)`
  ${ImageStyle}

  width: 50%;
  overflow: hidden;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .main-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    &:before {
      display: none;
    }

    .main-image {
      transform: none !important;
    }
  }
`

const Wrapper = styled.div`
  padding: 16.875rem 9.514vw;
  position: relative;
  z-index: 1;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const Label = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  max-width: 18.75rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.875rem;
  line-height: 6.875rem;

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 1.25rem;
`

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Thumb = styled.div`
  ${ImageStyle}

  height: 50%;
  position: relative;

  @media (max-width: 1199px) {
    img {
      display: block;
    }
  }
`

const Icons = styled(FlexBox)`
  height: 50%;

  > div {
    width: 50%;
    min-height: auto;
    &:nth-of-type(odd) {
      background: ${({ theme }) => theme.colors.variants.primaryLight};
      color: ${({ theme }) => theme.colors.variants.primaryDark};
    }
  }

  @media (max-width: 1199px) {
    height: auto;
    flex-wrap: wrap;

    > div {
      width: 100%;
    }
  }
`
