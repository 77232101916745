import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const AdvancedIntro = memo(function AdvancedIntro({
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title
            className="text-mark-xl"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </FadeInUp>
      ) : null}

      <Background>
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding-top: 13rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-top: 5.625rem;
  }
`

const Title = styled.h2`
  max-width: 27.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.875rem;
  line-height: 6.875rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 3.125rem;
    line-height: 3.125rem;
    padding: 0 1.875rem;
  }
`

const Description = styled.div`
  max-width: 54.375rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    padding: 0 1.875rem;
  }
`

const Background = styled.div`
  height: 77.8vh;
  max-height: 43.75rem;
  padding-top: 4rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 1)},
      ${rgba(theme.colors.variants.primaryDark, 0.3)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  > div {
    width: 100%;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 50vh;
    padding-top: 2.5rem;

    > div {
      top: 2.5rem;
    }
  }
`
